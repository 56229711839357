export const appConfig = {
  PORT: "3008",
  NODEURL: "/",
  PAYPAL_EMAIL: "sb-u4mbh32516264@business.example.com",
  PAYPAL_ENV: "https://www.paypal.com/cgi-bin/webscr",
  HOME: "https://register.contranooga.us",
  PAYPAL_CLIENT_ID:
    "AQ27N8MN2-g9NYzup2_rxK7MBi3OhuR3cEwLW8TS_jQ0-xxX-viRfGzelui9mCdOYiMLUjD-HzlTiNPk",
  THIRD_DOMAIN: "register.contranooga.us",
  PAYPAL_RESTBASEURL: "https://www.paypal.com",
 DANCERSCODE: "CTDS3233",
EARLYBIRDDATE: "2024-11-21",
EXCLUDEFIELDS: ["unit_amount", "quantity", "name"],
};

export const contact = {
  email: "dancers@contranooga.us",
  message: "4138137109",
};

const isEarlyBird = new Date(appConfig.EARLYBIRDDATE) > new Date();

export const prices = {
  Weekend: [130, 90, 145, 100],
  Friday: [35, 25, 35, 25],
  Saturday: [35, 25, 35, 25],
  SaturdayNight: [40, 30, 40, 30],
  Sunday: [35, 25, 35, 25],
};

const mprice = isEarlyBird
  ? Object.entries(prices)[0][1][0]
  : Object.entries(prices)[0][1][2];

export const _fields = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  student: false,
  housing: false,
  // dietary: "omnivore",
  price: mprice,
  unit_amount: mprice,
  quantity: 1,
  // waltzattend: "select...",
  name: "dance_admission",
  event: "Weekend",
  createdOn: "",
  orderId: "",
};

export const playlist = [
  {
    url: "https://youtu.be/cb4-nbYOU5k",
    label: "2020 Chattaboogie One",
  },
  {
    url: "https://youtu.be/a-LfWhcotRM",
    label: "2020 Chattaboogie Two",
  },
  {
    url: "https://youtu.be/rwjMxc5GIUQ",
    label: "2020 Chattaboogie Three",
  },
  {
    url: "https://youtu.be/gjV5zm0BYkY",
    label: "2020 Chattaboogie Four",
  },
  {
    url: "https://youtu.be/i0NVg80JLT8",
    label: "Angel Band",
  },
  {
    url: "https://youtu.be/r4nWJGow-Qw",
    label: "Waltz",
  },
];
