import React from "react";
// import BraintreeDropIn from "../components/BraintreeDropIn";
import BraintreeHostedFields from "../components/BraintreeHostedFields";

const Braintree = () => {
  return (
    <div>
      <div>Braintree</div>
      <BraintreeHostedFields />
    </div>
  );
};

export default Braintree;
